import React from 'react';

import Layout from '../components/Layout';

import whiteMovingTruckOnRoad from '../assets/images/white-semi-truck-on-road.jpg';
import pic2 from '../assets/images/pic02.jpg';
import catInMovingBox from '../assets/images/gray-cat-inside-a-box.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import n95 from '../assets/images/n95-respirator.png';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <a href="#moving-quote" className="get-my-quote">
          Get my quote now
        </a>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img
              src={whiteMovingTruckOnRoad}
              alt="white moving truck on the road"
            />
          </a>
          <div className="content">
            <h2 className="major">Licensed long distance moving companies</h2>
            <p>
              We work with dozens of long distance moving companies, each one
              has all of the paperwork required by the United States Department
              of Transportation to legally move your items. Its very important
              to only hire a licensed moving carrier when planning a long
              distance move. Find out more about <a>the difference</a> between
              licensed moving companies as opposed to moving brokers.
            </p>
            <a href="/LongDistanceMovers" className="special">
              Learn more
            </a>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/HowItWorks" className="image">
            <img src={pic2} alt="woman sitting in front of skyline view" />
          </a>
          <div className="content">
            <h2 className="major">
              How can best movers help me find movers near me
            </h2>
            <p>
              With our large network of moving companies, we can make you a
              reservation with the best movers near you. We will take care of
              arranging every last detail to make sure that everything goes
              smooth.
            </p>
            <a href="/HowItWorks" className="special">
              Learn more
            </a>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/MovingFaq" className="image">
            <img
              src={catInMovingBox}
              alt="gray cat inside a purple moving box"
            />
          </a>
          <div className="content">
            <h2 className="major">Will movers ...?</h2>
            <p>
              Looking for long distance moving tips? Want a{' '}
              <a>moving checklist</a>? What is a moving estimate? How do long
              distance moving companies charge? Who regulates cross country
              moving companies? We have answers.
            </p>
            <a href="/MovingFaq" className="special">
              Answers to the most frequent questions
            </a>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>

      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Check out our blog for moving tips </h2>
          <p></p>
          <section className="features">
            <article>
              <a href="/coronavirus" className="image">
                <img src={n95} alt="n95 respirator" />
              </a>
              <h3 className="major">Moving during coronavirus</h3>
              <p>
                A lot of people have questions about moving during the coronavirus. Here we have answers to some of your questions about how to safely hire movers during the Coronavirus outbreak.
              </p>
              <a href="/coronavirus" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/Fullpacking" className="image">
                <img src={pic4} alt="" />
              </a>
              <h3 className="major">Movers who pack everything</h3>
              <p>
                Our movers are also expert packers. We can take care of packing
                your entire house for you in no time so that you can worry about
                doing the things you actually want to do.
              </p>
              <a href="/Fullpacking" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/LoadingAndUnloading" className="image">
                <img src={pic5} alt="" />
              </a>
              <h3 className="major">Our movers move everything</h3>
              <p>
                Looking for movers to transport your car? We partner with the
                nations best auto shipping company. Looking for movers to move a
                piano? We handle piano moving. Want to have movers store your
                stuff? No problem.
              </p>
              <a href="/LoadingAndUnloading" className="special">
                Learn more
              </a>
            </article>

            <article>
              <a href="/CommercialMoving" className="image">
                <img src={pic7} alt="library with very tall curving bookcase" />
              </a>
              <h3 className="major">Commercial moving</h3>
              <p>
                We have lots of experience moving offics, moving warehouses, and
                even moving libraries.
              </p>
              <a href="/CommercialMoving" className="special">
                Learn more
              </a>
            </article>
          </section>
          <ul className="actions">
            <li>
              <a href="/Blog" className="button">
                Browse All
              </a>
            </li>
            <li>
              {' '}
              <a href="#moving-quote" className="get-my-quote">
                Get my quote now
              </a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
